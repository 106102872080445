// 商品类型
export const productType = {
  10: "账号直充",
  20: "卡密兑换",
  90: "其他",
};

// 商品状态
export const productStatus = {
  10: ["上架", "#67C23A"],
  20: ["下架", "#F56C6C"],
  30: ["缺货", "#E6A23C"],
};

// 商品分类状态
export const cateStatus = {
  10: "有效",
  20: "无效",
};

// 订单状态
export const orderStatus = {
  10: "待支付",
  20: "待处理",
  30: "处理中",
  40: "成功",
  50: "失败",
  60: "已取消",
};

// 抵扣类型
export const deductionType = {
  10: "卡券抵扣",
  20: "积分抵扣",
  90: "无抵扣",
};

// 支付类型
export const payType = {
  10: "积分支付",
  20: "卡券支付",
  30: "现金支付",
  40: "组合支付",
};

export const couponTypeList = {
  10: "满减券",
  20: "折扣券",
  30: "随机金额券",
  40: "商品兑换券",
  50: "储值券",
};

// 售后状态
export const serviceStatus = {
  10: "退款申请中",
  20: "退款处理中",
  30: "退款成功",
  40: "退款失败",
  50: "退款失败",
};

// 支付方式
export const payModeList = {
  10: "支付宝支付",
  20: "微信支付",
};

// 优惠券领取状态
export const couponStatus = {
  10: "立即领取",
  20: "去使用",
  30: "继续领取",
  40: "已领取",
};
