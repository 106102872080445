<template>
  <div class="service-details">
    <div class="apply-process" v-if="serviceInfo.afterSaleStatus">
      <div class="content-box">
        <div class="process-title">
          <div class="title-box">
            <img
              v-if="serviceInfo.afterSaleStatus == 30"
              src="../assets/images/service-success.png"
              alt=""
            />
            <img
              v-if="
                serviceInfo.afterSaleStatus == 40 ||
                serviceInfo.afterSaleStatus == 50
              "
              src="../assets/images/service-error.png"
              alt=""
            />
            <span class="title-con">{{ serviceTitle }}</span>
          </div>
          <div class="handle-title">
            {{
              `${timeText}${
                serviceInfo.afterSaleStatus == 30 ||
                serviceInfo.afterSaleStatus == 40 ||
                serviceInfo.afterSaleStatus == 50
                  ? serviceInfo.afterSaleCompleteTime || ""
                  : serviceInfo.afterSaleCeatorTime || ""
              }`
            }}
          </div>
          <div
            v-if="
              serviceInfo.afterSaleStatus == 40 ||
              serviceInfo.afterSaleStatus == 50
            "
            class="handle-text"
          >
            如对处理结果有异议，请点击“联系客服”
          </div>
        </div>
        <div
          class="process-content"
          v-if="
            serviceInfo.afterSaleStatus == 10 ||
            serviceInfo.afterSaleStatus == 20
          "
        >
          <van-steps
            :active="active"
            active-color="#FF3B21"
            inactive-color="#999999"
          >
            <van-step>
              <div class="item-time">
                {{ serviceInfo.afterSaleCeatorTime || "" }}
              </div>
              <div class="step-item-text item1">提交申请</div>
            </van-step>
            <van-step>
              <div class="item-time">
                {{ serviceInfo.afterSaleHandleTime || "" }}
              </div>
              <div class="step-item-text">处理中</div>
            </van-step>
            <van-step>
              <div class="item-time">
                {{ serviceInfo.afterSaleCompleteTime || "" }}
              </div>
              <div class="step-item-text item3">退款成功</div>
            </van-step>
          </van-steps>
        </div>
      </div>
    </div>
    <div
      class="handle-refund"
      v-if="
        serviceInfo.afterSaleStatus == 10 || serviceInfo.afterSaleStatus == 20
      "
    >
      <span class="refund-title">您已成功发起退款申请，请耐心等待…</span>
      <span class="refund-tel">客服联系电话：400-001-2806</span>
    </div>
    <div class="refund-result" v-if="serviceInfo.afterSaleStatus == 30">
      <div class="result-title">
        <div class="title-left">
          <img src="../assets/images/service-money.png" alt="" />
          <span class="title">退款金额</span>
        </div>
        <span class="refund-money"
          >￥{{ mathManage.formatMoney(serviceInfo.afterSalePrice, 2) }}</span
        >
      </div>
      <div class="result-tip">
        注：退款金额将原路返回至付款账户，此处仅做资金流的展示。
      </div>
    </div>
    <div class="order-info-title">退款信息</div>
    <div class="order-info">
      <div class="img-box">
        <img
          :src="
            serviceInfo.productType == 30
              ? require('../assets/images/bill.png')
              : serviceInfo.productImg || defaultProductImg
          "
          alt=""
        />
      </div>
      <div class="goods-box">
        <h2 class="one-line-hidden">
          <span> {{ serviceInfo.productName || "没有获取信息" }}</span>
          <div class="goods-price">
            ￥{{ mathManage.formatMoney(serviceInfo.productPrice, 2) || "1" }}
          </div>
        </h2>
        <div class="price-box">
          <div class="price">
            <span>
              {{
                renderAttrName(
                  serviceInfo.productSpecName,
                  serviceInfo.productAttrName
                )
              }}
            </span>
          </div>
          <span class="buy-num">x{{ serviceInfo.afterSaleQty }}</span>
        </div>
        <div class="goods-type">
          <span>{{ productType[serviceInfo.deliveryMode] }}</span>
        </div>
      </div>
    </div>
    <div class="pay-info">
      <div class="pay-info-item">
        <span class="title">订单编号</span>
        <div class="content">{{ serviceInfo.orderId }}</div>
        <span
          class="copy tag-read-account"
          :data-clipboard-text="serviceInfo.orderId"
          @click="copy('tag-read-account')"
          >复制</span
        >
      </div>
      <div class="pay-info-item">
        <span class="title">退款金额</span>
        <div class="content">
          ￥{{ mathManage.formatMoney(serviceInfo.afterSalePrice, 2) }}
        </div>
      </div>
      <div class="pay-info-item">
        <span class="title">申请时间</span>
        <div class="content">{{ serviceInfo.afterSaleCeatorTime }}</div>
      </div>
      <div class="service-info" v-if="serviceList.length > 0">
        <div class="left-box" @click="serviceAction(serviceList[0])">
          <a :href="serviceList[0].href">
            <img class="service-img" :src="serviceList[0].src" alt="" />
            <span class="service-text">{{ serviceList[0].name }}</span>
          </a>
        </div>
        <div v-if="serviceList[1]" class="center-box"></div>
        <div
          v-if="serviceList[1]"
          class="right-box"
          @click="serviceAction(serviceList[1])"
        >
          <a :href="serviceList[1].href">
            <img class="service-img" :src="serviceList[1].src" alt="" />
            <span class="service-text">{{ serviceList[1].name }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Step, Steps } from "vant";
import Clipboard from "clipboard";
import mathManage from "@/utils/mathManage";
import { productType } from "@/utils/dictionary";
import { orderdetail, GetOrderAfterSaleDetail } from "@/api/common";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "ServiceDetails",
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      productType,
      mathManage,
      active: 1,
      serviceInfo: {},
    };
  },
  computed: {
    ...mapState(["defaultProductImg", "serviceList"]),
    serviceTitle() {
      const obj = {
        10: "已提交申请，请耐心等待…",
        20: "处理中，请耐心等待…",
        30: "退款成功",
        40: "退款失败",
        50: "退款失败",
      };
      return obj[this.serviceInfo.afterSaleStatus] || "";
    },
    timeText() {
      if (
        this.serviceInfo.afterSaleStatus == 10 ||
        this.serviceInfo.afterSaleStatus == 20
      ) {
        return "申请发起时间：";
      }
      if (
        this.serviceInfo.afterSaleStatus == 30 ||
        this.serviceInfo.afterSaleStatus == 40 ||
        this.serviceInfo.afterSaleStatus == 50
      ) {
        return "处理结束时间：";
      }
      return "";
    },
  },
  created() {
    document.title = "退款/售后";
    const orderId = this.$route.params.id;
    this.orderId = orderId;
    this.getDetails(orderId);
    this.getServeiceData(orderId);
  },
  methods: {
    ...mapActions(["serviceAction"]),

    // 查询详情
    async getDetails(orderId) {
      const res = await orderdetail({
        orderId,
      });
      if (res && res.code == 0) {
        this.serviceInfo = { ...this.serviceInfo, ...res.data };
      } else {
        this.$toast(res.message);
      }
    },

    async getServeiceData(orderId) {
      const res = await GetOrderAfterSaleDetail({
        orderId,
      });
      if (res && res.code == 0) {
        this.serviceInfo = { ...this.serviceInfo, ...res.data };
      } else {
        this.$toast(res.message);
      }
    },

    // 复制账号
    copy(name) {
      const clipboard = new Clipboard(`.${name}`);
      clipboard.on("success", (e) => {
        this.$toast.success("复制成功！");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast.success("该浏览器不支持自动复制！");
        // 释放内存
        clipboard.destroy();
      });
    },
    renderAttrName(productSpecName, productAttrName) {
      if (!productSpecName && !productAttrName) return "";
      if (!productAttrName) return productSpecName;
      return `${productSpecName};${productAttrName}`;
    },
  },
};
</script>

<style lang="less" scoped>
.service-details {
  height: 100%;
  padding-bottom: 1rem;
  overflow-y: auto;

  .apply-process {
    background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
    padding: 0.18rem;
    box-sizing: border-box;

    .content-box {
      background-color: #fff;
      border-radius: 0.24rem;
      height: 100%;
      overflow: hidden;
      padding: 0.42rem 0.32rem 0.16rem;
      box-sizing: border-box;
    }

    .process-title {
      margin-bottom: 0.18rem;

      .title-box {
        display: flex;
        align-items: center;

        img {
          width: 0.39rem;
          height: 0.39rem;
          margin-right: 0.18rem;
        }

        .title-con {
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .handle-title,
      .handle-text {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      .handle-title {
        margin-top: 0.1rem;
      }

      .handle-text {
        margin-top: 0.04rem;
      }
    }

    .process-content {
      /deep/ .van-step__circle-container {
        top: 44px;
      }
      /deep/ .van-step__line {
        top: 44px;
      }

      /deep/ .van-step__circle {
        width: 10px;
        height: 10px;
      }

      /deep/ .van-step__icon--active {
        font-size: 16px;
      }

      .item-time {
        height: 16px;
        font-size: 0.2rem;
      }

      .step-item-text {
        text-align: center;

        &.item1 {
          text-align: left;
        }

        &.item3 {
          text-align: right;
        }
      }
    }
  }

  .handle-refund {
    height: 1.4rem;
    background: #ffffff;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.24rem;
    box-sizing: border-box;

    .refund-title {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .refund-tel {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 0.2rem;
    }
  }

  .refund-result {
    background: #ffffff;
    margin-top: 0.2rem;
    padding: 0.28rem 0.24rem 0.36rem;
    box-sizing: border-box;

    .result-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-left {
        display: flex;
        align-items: center;

        img {
          width: 0.4rem;
          height: 0.36rem;
        }

        .title {
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff503f;
          margin-left: 0.14rem;
        }
      }

      .refund-money {
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff503f;
      }
    }

    .result-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 0.2rem;
    }
  }

  .order-info-title {
    height: 0.92rem;
    background: #ffffff;
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
    padding: 0 0.24rem;
  }

  .order-info {
    display: flex;
    padding: 0 0.4rem 0.32rem;
    box-sizing: border-box;
    background-color: #fff;

    .img-box {
      width: 2.04rem;
      height: 1.26rem;
      border-radius: 0.04rem;
      overflow: hidden;
      margin-right: 0.2rem;
      background-color: chocolate;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .goods-box {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .one-line-hidden {
        display: flex;
        justify-content: space-between;
        font-size: 0.28rem;
        > div {
          color: #ff503f;
        }
      }
      h2 {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      .price-box {
        display: flex;
        justify-content: space-between;

        .price {
          font-size: 0.2rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #ff503f;
          display: flex;
          align-items: center;

          span {
            font-size: 0.22rem;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #666666;
            line-height: 0.26rem;
            margin-right: 0.04rem;
          }
        }

        .buy-num {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

      .goods-type {
        display: flex;
        align-items: center;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 0.4rem;
          background: #f9f9f9;
          border-radius: 0.04rem;
          padding: 0 0.08rem;
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }

  .pay-info {
    background: #ffffff;
    margin-top: 0.2rem;
    padding: 0.32rem 0.4rem 0;

    .pay-info-item {
      display: flex;
      align-items: center;
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 0.32rem;
      &:last-child {
        margin-bottom: 0;
      }

      .title {
        width: 1.44rem;
        display: flex;
        align-items: center;
      }
      .content {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .copy {
        width: 0.6rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff4800;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .service-info {
    border-top: 0.02rem solid #f5f5f5;
    display: flex;
    align-items: center;

    .left-box {
      flex: 1;
      height: 0.8rem;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .center-box {
      width: 0.02rem;
      height: 0.48rem;
      background-color: #f5f5f5;
    }
    .right-box {
      flex: 1;
      height: 0.8rem;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .service-img {
      width: 0.4rem;
      height: 0.4rem;
    }

    .service-text {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-left: 0.2rem;
    }
  }

  .service-connect {
    padding: 0 0.4rem;
    box-sizing: border-box;
    margin-top: 0.2rem;

    a {
      height: 0.8rem;
      border-radius: 0.4rem;
      border: 0.02rem solid #d8d8d8;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
